import React, { memo } from 'react';
import { Button, Checkbox, InputField, LineSpinner, RawHtml, Text } from '~/shared/components';
import { useForm, useTranslation } from '~/shared/utils';
import {
    StyledActionField,
    StyledContent,
    StyledForm,
    StyledContentWrapper,
    StyledTerms,
    StyledActionContent,
    StyledSignUpButton,
} from './styled';
import { PasswordToggle } from '~/shared/components/FormElements';
import { useClub } from '~/features/club/hooks';
import { CreateClubMemberArgs } from '~/lib/data-contract';

export const SignUp = memo(() => {
    const { translate } = useTranslation();
    const {
        toggleSignUpActive,
        addCreateUser,
        createIsSuccess,
        createData,
        createIsLoading,
        loginPage: loginPageData,
        loginPageLoading: loginPageDataIsLoading,
    } = useClub();
    const { newsletterDescription, signUpTerms, clubName } = loginPageData || {};

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm({
        mode: 'onTouched',
        validationSchema: 'signUp',
        shouldUnregister: true,
    });

    const [email, password] = watch(['email', 'password']);

    const onSubmit = (values: CreateClubMemberArgs) => {
        addCreateUser({ ...values, signUpSource: 3 });
    };

    if (createIsSuccess) {
        return (
            <StyledContent>
                <Text as="h1" variant="display4">
                    {translate('club.signUp.confirmationHeadline')}
                </Text>
                <Text as="span">
                    <RawHtml
                        html={translate('club.signUp.confirmation', {
                            email: `<strong>${createData?.email}</strong>`,
                        })}
                    />
                </Text>
                <StyledActionField>
                    <Button onClick={() => toggleSignUpActive(false)}>
                        {translate('club.signUp.confirmationButtonText')}
                    </Button>
                </StyledActionField>
            </StyledContent>
        );
    }

    return (
        <StyledContentWrapper>
            <StyledContent isLoading={loginPageDataIsLoading || createIsLoading}>
                <Text as="h1" variant="display4">
                    {clubName}
                </Text>
                <Text as="span">
                    <RawHtml
                        html={translate('club.signUp.description', {
                            clubName: `<strong>${clubName}</strong>`,
                        })}
                    />
                </Text>
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <InputField
                        {...register('email')}
                        invalidMessage={errors.email?.message}
                        isActive={!!email}
                        autoComplete="email"
                        label={translate('form.label.email')}
                        placeholder={translate('form.placeholder.email')}
                    />
                    <PasswordToggle
                        {...register('password')}
                        invalidMessage={errors.password?.message}
                        isActive={!!password}
                        label={translate('form.label.password')}
                        placeholder={translate('form.placeholder.password')}
                        type="password"
                    />

                    <Checkbox
                        {...register('newsletter')}
                        isInvalid={Boolean(errors.newsletter)}
                        invalidMessage={errors.newsletter?.message}
                        label={newsletterDescription && <RawHtml html={newsletterDescription} />}
                        alignment="start"
                    />
                    <StyledActionContent>
                        {signUpTerms && (
                            <StyledTerms>
                                <RawHtml html={signUpTerms} />
                            </StyledTerms>
                        )}
                        <StyledSignUpButton>
                            <Button disabled={createIsLoading}>
                                {translate('club.signUp.submit')}
                            </Button>
                        </StyledSignUpButton>
                    </StyledActionContent>
                </StyledForm>
            </StyledContent>
            {createIsLoading && <LineSpinner width={100} delay={1} />}
        </StyledContentWrapper>
    );
});
