import React, { useCallback, useState } from 'react';
import { SocialLoginButton } from '../SocialLoginButton';
import FacebookIcon from '$icons/facebook-color.svg';
import Script from 'next/script';
import { facebookSDKSrc, getLoginStatusResponse, initFacebookSDK } from '~/features/authentication';
import { useTranslation } from '~/shared/utils';
import { useClub } from '~/features/club/hooks';
type Props = {
    disabled?: boolean;
    callbackUrl?: string;
    facebookAppId?: string;
};
export const FacebookLogin = ({ disabled, callbackUrl = '/', facebookAppId }: Props) => {
    const [invocationhasError, setInvocationHasError] = useState(false);
    const { translate } = useTranslation();
    const { signInUser } = useClub();

    const doLogin = useCallback(
        (accessToken?: string) => {
            // Sign in with own endpoint
            if (accessToken) {
                signInUser({ facebookToken: accessToken }, callbackUrl);
            }
        },
        [callbackUrl, signInUser]
    );

    const attemptLogin = useCallback(async () => {
        const response = await getLoginStatusResponse();

        switch (response.status) {
            case 'authorization_expired':
            case 'not_authorized':
            case 'unknown':
                FB.login(
                    (response) => {
                        doLogin(response.authResponse?.accessToken);
                    },
                    { scope: 'email', return_scopes: true }
                );
                break;
            case 'connected':
                doLogin(response.authResponse?.accessToken);
                break;
        }
    }, [doLogin]);

    const init = useCallback(() => {
        if (!facebookAppId) {
            return;
        }

        try {
            initFacebookSDK(facebookAppId);
        } catch {
            setInvocationHasError(true);
        }
    }, [facebookAppId]);

    if (!facebookAppId || invocationhasError) {
        return <></>;
    }

    return (
        <>
            <SocialLoginButton
                onClick={attemptLogin}
                aria-label={translate('club.signIn.loginFacebook')}
            >
                <FacebookIcon disabled={disabled} />
            </SocialLoginButton>
            <Script src={facebookSDKSrc} onLoad={init} />
        </>
    );
};
