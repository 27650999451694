import { GoogleSDKError } from './exceptions';

type InitButtonArgs = {
    domNode: HTMLElement;
    client_id: string;
    signInCallback: (response: google.accounts.id.CredentialResponse) => void;
};

export const googleAccountSDKSrc = 'https://accounts.google.com/gsi/client';

export const initSignInButton = ({ domNode, client_id, signInCallback }: InitButtonArgs) => {
    if (!client_id) {
        throw new GoogleSDKError('Missing client_id cannot continue');
    }

    if (!domNode) {
        throw new GoogleSDKError('Missing domNode to inject sign in button cannot continue');
    }

    google.accounts.id.initialize({
        client_id,
        callback: signInCallback,
        cancel_on_tap_outside: true,
    });

    google.accounts.id.renderButton(domNode, {
        type: 'icon',
        shape: 'square',
        theme: 'outline',
        size: 'large',
    });
};
