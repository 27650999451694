import React, { memo } from 'react';
import { useAccount, useClub } from '~/features/club';
import { StyledDeleteAccount } from './styled';
import { Text } from '~/shared/components';
import { Button, TextBlock } from '~/shared/components';
import { Spacer } from '~/theme';
import { useTranslation } from '~/shared/utils';
import { useEffect } from 'react';

export const DeleteAccount = memo(() => {
    const { toggleDeleteAccountActive } = useClub();
    const { translate } = useTranslation();
    const { deleteAccount, deleteAccountIsLoading, deleteAccountIsSuccess } = useAccount();

    useEffect(() => {
        if (deleteAccountIsSuccess) {
            toggleDeleteAccountActive(false);
        }
    }, [toggleDeleteAccountActive, deleteAccountIsSuccess]);

    return (
        <TextBlock>
            <Text children={translate('club.deleteAccount.dialogDescription')} />
            <Spacer space="9" />
            <StyledDeleteAccount>
                <Button variant="secondary" onClick={() => toggleDeleteAccountActive(false)}>
                    {translate('club.deleteAccount.cancelButtonText')}
                </Button>
                <Button onClick={() => deleteAccount()} disabled={deleteAccountIsLoading}>
                    {translate('club.deleteAccount.continueButtonText')}
                </Button>
            </StyledDeleteAccount>
        </TextBlock>
    );
});
