import React, { useCallback, useRef, useState } from 'react';
import Script from 'next/script';
import { googleAccountSDKSrc, initSignInButton } from '~/features/authentication';
import { StyledGoogleSigninButton, StyledGoogleSigninButtonWrapper } from './styled';
import { useClub } from '~/features/club/hooks';
import { useNotification } from '~/shared/hooks';
import { useEffectOnce } from 'react-use';

type Props = {
    disabled?: boolean;
    callbackUrl?: string;
    clientId?: string;
};

export const GoogleLogin = ({ disabled, clientId, callbackUrl = '/' }: Props) => {
    const [invocationhasError, setInvocationHasError] = useState(false);
    const node = useRef<HTMLDivElement>(null);
    const { signInUser } = useClub();
    const { push } = useNotification();

    const signInCallback = useCallback(
        (response: google.accounts.id.CredentialResponse) => {
            // Sign in with own endpoint
            if (response.credential) {
                signInUser({ googleToken: response.credential }, callbackUrl);
                return;
            }

            // Something went wrong throw error notification
            push({});
        },
        [callbackUrl, push, signInUser]
    );

    const init = useCallback(() => {
        if (!clientId) {
            return;
        }

        try {
            initSignInButton({
                client_id: clientId,
                domNode: node.current as HTMLElement,
                signInCallback,
            });
        } catch {
            setInvocationHasError(true);
        }
    }, [clientId, signInCallback]);

    useEffectOnce(() => {
        if (clientId && window.google?.accounts) {
            init();
        }
    });

    if (!clientId || invocationhasError) {
        return <></>;
    }

    return (
        <>
            <StyledGoogleSigninButtonWrapper>
                <StyledGoogleSigninButton disabled={disabled} ref={node}></StyledGoogleSigninButton>
            </StyledGoogleSigninButtonWrapper>
            {node.current && <Script src={googleAccountSDKSrc} onLoad={init} />}
        </>
    );
};
