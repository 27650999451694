import styled from '@emotion/styled';
import { breakpoints } from '$theme';
import { getClampFullRange } from '~/theme/utils/getClampValue';

export const StyledFooter = styled.footer(({ theme }) => ({
    backgroundColor: theme.colors.light,
    borderTop: theme.traits.line.asBorderStyle,
    '&:empty': {
        display: 'none',
    },
}));

export const StyledFooterTrustpilotWrapper = styled.div(({ theme }) => ({
    padding: `${getClampFullRange(theme.spacesRaw[4], theme.spacesRaw[6])} 0`,
    borderBottom: theme.traits.line.asBorderStyle,
    display: 'grid',
    alignItems: 'center',
    minHeight: 200,
    [breakpoints.md]: {
        minHeight: 260,
    },
    [breakpoints.lg]: {
        minHeight: 300,
    },
}));

export const StyledGrid = styled.div(({ theme }) => ({
    padding: `${getClampFullRange(theme.spacesRaw[6], theme.spacesRaw[8])} 0 ${getClampFullRange(
        theme.spacesRaw[4],
        theme.spacesRaw[8]
    )}`,
    display: 'grid',
    rowGap: theme.spaces[5],
    columnGap: theme.gridConfig.gap,
    gridTemplateAreas: `"richtext"
                        "menu"
                        "newsletter"
                        "icons"`,
    '&:empty': {
        display: 'none',
    },
    [breakpoints.md]: {
        gridAutoFlow: 'column',
        gridTemplateColumns: `repeat(${theme.gridConfig.columnCount.md}, 1fr)`,
        gridTemplateAreas: `". richtext richtext richtext . menu menu menu menu newsletter newsletter newsletter"`,
    },
}));

export const StyledContactSection = styled.div(({ theme }) => ({
    display: 'grid',
    gap: theme.spaces[3],
    maxWidth: '40ch',
}));

export const StyledSubFooter = styled.div(({ theme }) => ({
    padding: `${theme.spaces[2]} ${theme.gridConfig.gutter}`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[2],
    borderTop: theme.traits.line.asBorderStyle,

    [breakpoints.md]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

export const StyledMetaRTEWrapper = styled.div(({ theme }) => ({
    borderTop: theme.traits.line.asBorderStyle,
    textAlign: 'left',
    padding: `${theme.spaces[1]} ${theme.gridConfig.gutter}`,

    [breakpoints.sm]: {
        textAlign: 'right',
    },

    p: {
        marginBottom: '0 !important',
    },

    'a, button': {
        fontSize: theme.fontSizesFluid.sm,
        display: 'inline-block',
        margin: `${theme.spaces[1]} 2ch ${theme.spaces[1]} 0`,
        [breakpoints.sm]: {
            margin: `${theme.spaces[1]} 0 ${theme.spaces[1]} 2ch`,
        },
    },
}));
