import React, { forwardRef, useMemo } from 'react';
import { InputFieldProps } from '~/shared/components/FormElements';
import { StyledDatePicker } from './styled';

export type Props = {
    /**
     * Supply an optional minimum year  which will be subtractedfrom the current date, it won't be possible to select
     * a year earlier than the supplied date
     * @example
     * Based on 2022-01-09
     * <DatePicker min={50} />
     * Will roughly result in:
     * <input type="date" min="1972-01-09" />
     */
    yearRangeMin?: number;
    /**
     * Supply an optional maximum year from the current date, it won't be possible to select
     * a year that's later than the supplied date
     */
    yearRangeMax?: number;
};

/**
 * Date picker that extends the native type="date", supports a range currently only year range is suported
 */
export const DatePicker = forwardRef<HTMLInputElement, InputFieldProps & Props>(
    ({ yearRangeMin: minimum = 100, yearRangeMax: maximum = 17, ...rest }, ref) => {
        const [minAtt, maxAtt] = useMemo(() => {
            const minimumDate = new Date();
            const maximumDate = new Date();
            minimumDate.setFullYear(minimumDate.getFullYear() - minimum);
            maximumDate.setFullYear(maximumDate.getFullYear() - maximum);

            return [minimumDate.toISOString().slice(0, 10), maximumDate.toISOString().slice(0, 10)];
        }, [maximum, minimum]);

        return (
            <StyledDatePicker
                type="date"
                ref={ref}
                min={minAtt}
                max={maxAtt}
                isActive={true}
                {...rest}
            />
        );
    }
);
