import React, { memo } from 'react';
import { useFrame, withErrorBoundary } from '$shared/utils';
import { Gutter, RawHtml, Text } from '~/shared/components';
import { GridItem, IconArea, MenuArea, NewsletterArea, SoMeItems } from './index';
import { N80Trustpilot } from '../N80Trustpilot';
import {
    StyledFooter,
    StyledGrid,
    StyledContactSection,
    StyledSubFooter,
    StyledMetaRTEWrapper,
    StyledFooterTrustpilotWrapper,
} from './styled';
import { FooterLogo } from '~/shared/components/Logo/FooterLogo';

export const N61Footer = memo(() => {
    const { data: frame } = useFrame();

    const {
        contactText,
        menu,
        newsletterText,
        newsletterLink,
        additionalIcons,
        socialLinks,
        footerMeta,
        trustpilotWidgetSnippet,
        copyrights,
    } = frame?.footer || {};

    const showGrid = menu || contactText || newsletterText || newsletterLink;

    return (
        <StyledFooter>
            {trustpilotWidgetSnippet && (
                <StyledFooterTrustpilotWrapper>
                    <N80Trustpilot markup={trustpilotWidgetSnippet} />
                </StyledFooterTrustpilotWrapper>
            )}
            {showGrid && (
                <Gutter>
                    <StyledGrid>
                        <GridItem area="richtext">
                            <StyledContactSection>
                                <FooterLogo size="large" />
                                {contactText && <RawHtml html={contactText} />}
                                {socialLinks && <SoMeItems items={socialLinks} />}
                            </StyledContactSection>
                        </GridItem>
                        {menu && <GridItem area="menu" children={<MenuArea menu={menu} />} />}
                        {newsletterText || newsletterLink ? (
                            <GridItem area="newsletter">
                                <NewsletterArea text={newsletterText} link={newsletterLink} />
                            </GridItem>
                        ) : null}
                    </StyledGrid>
                </Gutter>
            )}
            {footerMeta && (
                <StyledMetaRTEWrapper>
                    <RawHtml html={footerMeta} linkStrokeMode="whileHover" />
                </StyledMetaRTEWrapper>
            )}
            <StyledSubFooter>
                <IconArea
                    paymentIcons={frame?.ecommerce?.paymentIcons}
                    additionalIcons={additionalIcons}
                />
                <Text variant="bodySm">{copyrights ? copyrights : null}</Text>
            </StyledSubFooter>
        </StyledFooter>
    );
});

export default withErrorBoundary(N61Footer);
