import React, { memo } from 'react';
import { FacebookLogin } from '../FacebookLogin';
import { GoogleLogin } from '../GoogleLogin';
import { StyledSocialDivider, StyledSocialLogin } from './styled';
import { Text } from '~/shared/components';
import { useFrame, useTranslation } from '~/shared/utils';

type Props = {
    disabled?: boolean;
};

export const SocialLogin = memo(({ disabled }: Props) => {
    const { translate } = useTranslation();
    const { data: frame } = useFrame();
    const callbackUrl = frame?.staticLinks?.clubPage?.url;
    const facebookAppId = frame?.settings?.features?.facebookToken;
    const googleToken = frame?.settings?.features?.googleToken;

    if (!facebookAppId && !googleToken) {
        return <></>;
    }

    return (
        <>
            <StyledSocialDivider>
                <Text variant="overline" children={translate('club.signIn.continueWithSocial')} />
            </StyledSocialDivider>
            <StyledSocialLogin>
                <FacebookLogin
                    disabled={disabled}
                    callbackUrl={callbackUrl}
                    facebookAppId={facebookAppId}
                />
                <GoogleLogin disabled={disabled} callbackUrl={callbackUrl} clientId={googleToken} />
            </StyledSocialLogin>
        </>
    );
});
