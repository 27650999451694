import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledClubOverlays = styled.div(({ theme }) => ({
    position: 'relative',
    zIndex: theme.zIndices.max,
}));

export const StyledSignInContainer = styled.div(({ theme }) => ({
    position: 'fixed',
    inset: '0 0 auto 0',
    zIndex: theme.zIndices['drawer'] - 1,
    [breakpoints.md]: {
        marginLeft: 'auto',
        maxWidth: '460px',
    },
}));

export const StyledClubDialogContainer = styled.div(({ theme }) => ({
    containerName: 'styledClubDialogContainer',
    containerType: 'inline-size',
    padding: `${theme.spaces[4]} ${theme.spaces[3]}`,
    width: `calc(100vw - ${theme.gridConfig.gutter})`,

    [breakpoints.md]: {
        padding: `clamp(${theme.spaces[1]}, 5vh, ${theme.spaces[6]}) clamp(${theme.spaces[3]}, 5vw, ${theme.spaces[10]})`,
        width: 'unset',
    },
}));
