import React, { FormEvent, memo, useState } from 'react';
import { useEffect } from 'react';
import { useAccount } from '~/features/club';
import { StyledStickySubmit, StyledStoreItem } from './styled';
import { useClub, useClubPaths } from '~/features/club/hooks';
import { Button, Text, TextBlock } from '~/shared/components';
import { Form, useGetRequest, useTranslation } from '~/shared/utils';
import { StoreViewModel } from '~/lib/data-contract';

export const EditAccountStore = memo(() => {
    const {
        account = {},
        updatePermissions,
        updatePermissionsIsLoading,
        updatePermissionsIsSuccess,
        authHeaders,
    } = useAccount();
    const { toggleEditAccountStoreActive } = useClub();
    const { getStoresUrl } = useClubPaths();
    const {
        notifyByEmail,
        notifyBySms,
        storeId: acccountStoreId,
        storeName: accountStoreName,
    } = account;
    const [selectedStore, setSelectedStore] = useState<StoreViewModel>({
        storeId: acccountStoreId,
        storeName: accountStoreName,
    });
    const { translate } = useTranslation();

    const { data: stores = [] } = useGetRequest<StoreViewModel[]>(getStoresUrl, {
        headers: authHeaders,
    });

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        const { storeId, storeName } = selectedStore;
        const payload = {
            receiveEmail: notifyByEmail || false,
            receiveSms: notifyBySms || false,
            storeId,
            storeName,
        };
        updatePermissions(payload);
    };

    useEffect(() => {
        if (updatePermissionsIsSuccess) {
            toggleEditAccountStoreActive(false);
        }
    }, [updatePermissionsIsSuccess, toggleEditAccountStoreActive]);

    return (
        <TextBlock>
            <Text variant="display4" children={translate('club.editAccountStore.headline')} />

            <Form.Root onSubmit={onSubmit}>
                <Form.Column column="1 / span 6" />
                {stores.length ? (
                    stores.map((store) => (
                        <Form.Column column="1 / span 6" columnMd="span 2" key={store.storeId}>
                            <StyledStoreItem
                                type="button"
                                active={selectedStore.storeId === store.storeId}
                                onClick={() => setSelectedStore(store)}
                            >
                                <Text
                                    variant="bodySm"
                                    as="strong"
                                    shade={
                                        selectedStore.storeId === store.storeId
                                            ? 'paragraphInverted'
                                            : undefined
                                    }
                                >
                                    {store.storeName}
                                </Text>
                                <Text
                                    variant="bodySm"
                                    shade={
                                        selectedStore.storeId === store.storeId
                                            ? 'paragraphInverted'
                                            : undefined
                                    }
                                >
                                    {store.address}
                                </Text>
                            </StyledStoreItem>
                        </Form.Column>
                    ))
                ) : (
                    <Form.Column>{translate('club.editAccountStore.pendingText')}</Form.Column>
                )}

                <StyledStickySubmit>
                    <Form.Column>
                        {account.storeId !== selectedStore.storeId && (
                            <Button variant="secondary" disabled={updatePermissionsIsLoading}>
                                {translate('general.save')}
                            </Button>
                        )}
                    </Form.Column>
                </StyledStickySubmit>
            </Form.Root>
        </TextBlock>
    );
});
