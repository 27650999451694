import styled from '@emotion/styled';
import { breakpoints } from '~/theme';
import { getClampFullRange } from '~/theme/utils/getClampValue';

export const StyledN11Meta = styled.div(({ theme }) => ({
    position: 'relative',
    zIndex: theme.zIndices[4],
    backgroundColor: theme.traits.background.n11meta,
    display: 'flex',
    alignItems: 'center',
    height: theme.sizes.metaHeight,

    [breakpoints.md]: {
        height: theme.sizes.metaHeightDesktop,
    },
}));

export const StyledContent = styled.div(({ theme }) => ({
    padding: `${getClampFullRange(theme.spacesRaw[2], theme.spacesRaw[1])} ${
        theme.gridConfig.gutter
    }`,
    paddingLeft: theme.spacesRaw[2],
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.fontSizes.lg,
    '&:only-child': {
        margin: '0 auto',
    },
    [breakpoints.md]: {
        padding: `${getClampFullRange(theme.spacesRaw[2], theme.spacesRaw[1])} ${
            theme.gridConfig.gutter
        }`,
    },
}));
