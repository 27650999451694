import styled from '@emotion/styled';
import { StyledText } from '~/shared/components';

export const StyledContentWrapper = styled.div({
    position: 'relative',
});

export const StyledContent = styled.div<{ isLoading?: boolean }>(({ theme, isLoading }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[4],
    opacity: isLoading ? 0 : 1,
    transition: `opacity ${theme.animations.getDuration('slow01')} ${theme.animations.getEasing(
        'entrance',
        'productive'
    )} ${theme.animations.durations.fast02}s`,
}));

export const StyledForm = styled.form(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[4],
}));

export const StyledActionField = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
});

export const StyledSignUpButton = styled.div({
    display: 'flex',
    justifyContent: 'center',

    [`@container styledClubDialogContainer (min-width: 500px)`]: {
        justifyContent: 'flex-end',
    },
});

export const StyledActionContent = styled.div(({ theme }) => ({
    display: 'grid',
    justifyContent: 'center',
    gap: theme.spaces[4],

    [`@container styledClubDialogContainer (min-width: 500px)`]: {
        gap: theme.spaces[1],
        gridTemplateColumns: '1fr auto',
    },
}));

export const StyledTerms = styled.div(({ theme }) => ({
    fontSize: theme.fontSizes.sm,

    [`${StyledText}`]: {
        fontSize: theme.fontSizes.sm,
        lineHeight: '20px',
    },
}));
