import React from 'react';
import { StyledCrossLine, StyledLineContainer, StyledMobileMenuButton } from '../styled';

import { useTranslation } from '~/shared/utils/translation';
import { useMobileMenu } from '../../N35MobileMegaMenu/hooks/useMobileMenu';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';
import { useTheme } from '@emotion/react';

export const MobileMegaMenuButton = ({ isLight }: { isLight?: boolean }) => {
    const { toggle, isOpen } = useMobileMenu();
    const { translate } = useTranslation();
    const { colors } = useTheme();

    return (
        <StyledMobileMenuButton onClick={toggle}>
            <StyledLineContainer>
                <StyledCrossLine
                    thickness={2}
                    cross={isOpen}
                    color={!isLight ? colors.dark50 : colors.light}
                />
                {!isOpen && (
                    <StyledCrossLine
                        thickness={2}
                        cross={isOpen}
                        color={!isLight ? colors.dark50 : colors.light}
                    />
                )}
                <StyledCrossLine
                    thickness={2}
                    cross={isOpen}
                    color={!isLight ? colors.dark50 : colors.light}
                />
            </StyledLineContainer>
            <VisuallyHidden children={translate('navigation.mobile.mainMenu.name')} />
        </StyledMobileMenuButton>
    );
};
