import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledDeleteAccount = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[3],
    justifyContent: 'flex-end',
    [breakpoints.md]: {
        flexDirection: 'row',
    },
}));
