import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { m } from 'framer-motion';

export const StyledPromotedContent = styled(m.div, {
    shouldForwardProp: (prop: string) => prop !== 'itemLength',
})<{ itemLength?: number }>(
    switchProp('itemLength', {
        1: {
            flex: '0 1 calc((100% / 12) * 3)',
            paddingLeft: '1.25%', // Approximate adjustment for height dif
        },
        2: ({ theme }) => ({
            flex: '0 1 calc((100% / 12) * 5)',
            display: 'grid',
            gap: theme.gridConfig.gap,
            gridTemplateColumns: '3fr 2fr',
        }),
    })
);

export const StyledPromotedItemContent = styled.div(({ theme: { spaces } }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: spaces[2],
}));

export const StyledPromotedImageWrapper = styled.div<{
    w: number;
    h: number;
}>(({ w, h }) => ({
    aspectRatio: `${w} / ${h}`,
}));
