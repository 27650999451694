import styled from '@emotion/styled';

export const StyledStickySubmit = styled.div(({ theme: { spaces } }) => ({
    position: 'sticky',
    bottom: spaces[2],
    textAlign: 'center',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    gridColumn: '1 / span 6',
}));

export const StyledStoreItem = styled.button<{ active?: boolean }>(({ theme, active }) => ({
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spaces[2],
    padding: `${theme.spaces[2]} ${theme.spaces[1]} `,
    backgroundColor: active ? theme.colors.dark : theme.colors.light,
    borderRadius: 0,
    border: `1px solid ${theme.colors.light40}`,
    minHeight: 110,
}));
