import React, { memo, useMemo, useState, useEffect } from 'react';

import { Button, Selector, Text } from '~/shared/components';
import { useAccount, useClub } from '~/features/club';
import { InputField } from '~/shared/components/FormElements';
import { useTranslation } from '~/shared/utils';
import { useForm, Form } from '~/shared/utils';
import { useBringApi, ZipAutoComplete } from '~/features/checkout';
import { Spacer } from '~/theme';
import { Fields } from '~/shared/utils/form';
import { DatePicker } from '~/shared/components/FormElements';

type Gender = { value?: string; key?: string };

export const EditAccount = memo(() => {
    const { account = {}, updateUser, updateIsLoading, updateIsSuccess } = useAccount();
    const { toggleEditAccountActive } = useClub();
    const [selectedGender, setSelectedGender] = useState<Gender>({});
    const { translate } = useTranslation();
    const { hasPostalLookupSupport } = useBringApi();

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm({
        mode: 'onTouched',
        validationSchema: 'editAccount',
        defaultValues: account,
        shouldUnregister: true,
    });

    const values = watch();

    const genders = useMemo(
        () => [
            {
                key: 'm',
                value: translate('form.values.gender.man'),
            },
            {
                key: 'f',
                value: translate('form.values.gender.woman'),
            },
            {
                key: 'x',
                value: translate('form.values.gender.other'),
            },
        ],
        [translate]
    );

    const onSubmit = (values: Fields) => {
        const { gender: _, ...rest } = values;
        const gender = selectedGender.key;

        updateUser({
            gender,
            ...rest,
        });
    };

    useEffect(() => {
        if (updateIsSuccess) {
            toggleEditAccountActive(false);
        }
    }, [toggleEditAccountActive, updateIsSuccess]);

    useEffect(() => {
        setSelectedGender(() => genders.find(({ key }) => key === account.gender) || {});
    }, [account.gender, genders]);

    return (
        <Form.Root onSubmit={handleSubmit(onSubmit)}>
            <Form.Column>
                <Text children={translate('form.group.contactInformation')} variant="display4" />
            </Form.Column>
            <Form.Column>
                <InputField
                    {...register('email')}
                    invalidMessage={errors?.email?.message}
                    isActive={!!values.email}
                    autoComplete="email"
                    label={translate('form.label.email')}
                    placeholder={translate('form.placeholder.email')}
                    readOnly
                    disabled
                />
            </Form.Column>
            <Form.Column>
                <InputField
                    {...register('phone')}
                    invalidMessage={errors?.phone?.message}
                    isActive={!!values.phone}
                    type="tel"
                    autoComplete="tel-national"
                    label={translate('form.label.phone')}
                    placeholder={translate('form.placeholder.phone')}
                />
            </Form.Column>
            <Form.Column>
                <Spacer space="3" />
                <Text children={translate('form.group.personalInformation')} variant="display4" />
            </Form.Column>
            <Form.Column column="1 / span 3">
                <InputField
                    {...register('firstname')}
                    invalidMessage={errors?.firstname?.message}
                    isActive={!!values.firstname}
                    autoComplete="given-name"
                    label={translate('form.label.firstName')}
                    placeholder={translate('form.placeholder.firstName')}
                />
            </Form.Column>
            <Form.Column column="4 / span 3">
                <InputField
                    {...register('lastname')}
                    invalidMessage={errors?.lastname?.message}
                    isActive={!!values.lastname}
                    autoComplete="family-name"
                    label={translate('form.label.lastName')}
                    placeholder={translate('form.placeholder.lastName')}
                />
            </Form.Column>
            <Text variant="caption" children={translate('form.label.gender')} />
            <Form.Column>
                <Selector
                    {...register('gender')}
                    invalidMessage={errors.gender?.message}
                    options={genders}
                    label={translate('form.label.gender')}
                    placeholder={translate('form.placeholder.gender')}
                    onChangeHandler={(option) => setSelectedGender((option as unknown) as Gender)}
                    value={selectedGender.value || ''}
                />
            </Form.Column>
            <Form.Column>
                <DatePicker
                    {...register('birthday')}
                    label={translate('form.label.birthday')}
                    invalidMessage={errors?.birthday?.message}
                    autoComplete="bday"
                />
            </Form.Column>
            <Form.Column>
                <Spacer space="3" />
                <Text children={translate('form.group.address')} variant="display4" />
            </Form.Column>
            <Form.Column>
                <InputField
                    {...register('address.street')}
                    maxLength={35}
                    invalidMessage={errors.address?.street?.message}
                    isActive={!!values.address?.street}
                    autoComplete="street-address"
                    label={translate('form.label.streetName')}
                    placeholder={translate('form.placeholder.streetName')}
                />
            </Form.Column>
            <Form.Column column="1 / span 3">
                <ZipAutoComplete
                    {...register('address.zipCode')}
                    invalidMessage={errors.address?.zipCode?.message}
                    isActive={values.address?.zipCode}
                    autoComplete="postal-code"
                    type="tel"
                    onZipValidation={(city) =>
                        setValue('address.city', city, {
                            shouldValidate: !!city,
                        })
                    }
                />
            </Form.Column>
            <Form.Column column="4 / span 3">
                <InputField
                    {...register('address.city')}
                    invalidMessage={errors.address?.city?.message}
                    isActive={!!values.address?.city}
                    autoComplete="address-level2"
                    label={translate('form.label.city')}
                    placeholder={translate('form.placeholder.city')}
                    disabled={hasPostalLookupSupport}
                />
            </Form.Column>
            <Form.Column rightAlign>
                <Spacer space="3" />
                <Button disabled={updateIsLoading}>{translate('general.save')}</Button>
            </Form.Column>
        </Form.Root>
    );
});
