import { FacebookSDKError } from './exceptions';

export const facebookSDKSrc = 'https://connect.facebook.net/en_US/sdk.js';

export const initFacebookSDK = (appId: string) => {
    if (!FB) {
        throw new FacebookSDKError('Missing global FB namespace, SDK not initialized!');
    }

    FB.init({
        appId,
        cookie: true,
        xfbml: false,
        version: 'v15.0',
    });
};

export const getLoginStatusResponse = async (): Promise<fb.StatusResponse> => {
    return new Promise((resolve, reject) => {
        try {
            FB.getLoginStatus((response: fb.StatusResponse) => {
                resolve(response);
            });
        } catch (err) {
            const errorMessage =
                typeof err === 'string' ? err : 'Unknown error trying to get login status';
            reject(new FacebookSDKError(errorMessage));
        }
    });
};
