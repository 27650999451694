import React, { memo } from 'react';
import {
    StyledMenu,
    StyledList,
    StyledMenuWrapper,
    StyledListItem,
    StyledMenuCategory,
} from './styled';
import { NavigationLink } from '../NavigationLink';

import { Link, NavigationNode } from '~/lib/data-contract';
import { weakKey } from '~/shared/utils/jsx';
import { Variants } from 'framer-motion';
import { useTheme } from '@emotion/react';
import { useThemeShade } from '~/theme';

export const Menu = memo(
    ({
        nodes,
        variants,
        isLightHeader,
    }: {
        nodes: NavigationNode[];
        link?: Link;
        variants?: Variants;
        isLightHeader?: boolean;
    }) => {
        const { traits } = useTheme();
        const { textShade, headlineShade } = useThemeShade({
            backgroundColor: traits.background.n21mainMenu,
        });

        return (
            <StyledMenuWrapper variants={variants}>
                <StyledMenu>
                    {nodes?.map((subNode) => (
                        <StyledMenuCategory key={weakKey(subNode)}>
                            <NavigationLink
                                link={subNode.link}
                                linkVariant="header"
                                textShade={!isLightHeader ? headlineShade : 'headline'}
                            />
                            <StyledList quantity={subNode.children?.length || 0}>
                                {subNode.children?.map(({ link }, index) => (
                                    <StyledListItem key={index}>
                                        <NavigationLink
                                            link={link}
                                            linkVariant="link"
                                            textShade={!isLightHeader ? textShade : 'paragraph'}
                                        />
                                    </StyledListItem>
                                ))}
                            </StyledList>
                        </StyledMenuCategory>
                    ))}
                </StyledMenu>
            </StyledMenuWrapper>
        );
    }
);
