import styled from '@emotion/styled';
import { InputField } from '~/shared/components/FormElements/components/InputField';
import { breaks } from '~/theme';

export const StyledDatePicker = styled(InputField)(({ theme }) => ({
    [`@media (max-width: ${breaks.md}px)`]: {
        '-webkit-appearance': 'none',
        display: 'flex',
        color: theme.colors.dark70,
        background: theme.colors.light,
    },
}));
