import React, { memo } from 'react';
import { useEffect } from 'react';
import { useAccount, useClub } from '~/features/club/hooks';
import { Button, InputField, Text, TextBlock } from '~/shared/components';
import { PasswordToggle } from '~/shared/components/FormElements';
import { useForm, useTranslation } from '~/shared/utils';
import { Fields } from '~/shared/utils/form';
import { Form } from '~/shared/utils/form/components/Form/Form';
import { Spacer } from '~/theme';

export const EditPassword = memo(() => {
    const { translate } = useTranslation();
    const { updatePassword, updatePasswordIsLoading, updatePasswordIsSuccess } = useAccount();
    const { toggleEditPasswordActive } = useClub();
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm({
        validationSchema: 'editPassword',
        shouldUnregister: true,
    });

    useEffect(() => {
        if (updatePasswordIsSuccess) {
            toggleEditPasswordActive(false);
        }
    }, [toggleEditPasswordActive, updatePasswordIsSuccess]);

    const [currentPassword, newPassword] = watch(['currentPassword', 'newPassword']);

    const onSubmit = (values: Fields) => {
        updatePassword(values);
    };

    return (
        <TextBlock>
            <Text variant="display4" children={translate('club.profile.editPasswordHeader')} />
            <Form.Root onSubmit={handleSubmit(onSubmit)}>
                <Form.Column>
                    <InputField
                        {...register('currentPassword')}
                        invalidMessage={errors.currentPassword?.message}
                        isActive={!!currentPassword}
                        label={translate('form.label.oldPassword')}
                        placeholder={translate('form.placeholder.oldPassword')}
                        type="password"
                    />
                </Form.Column>
                <Form.Column>
                    <PasswordToggle
                        {...register('newPassword')}
                        invalidMessage={errors.newPassword?.message}
                        isActive={!!newPassword}
                        label={translate('form.label.newPassword')}
                        placeholder={translate('form.placeholder.newPassword')}
                        type="password"
                    />
                </Form.Column>
                <Form.Column rightAlign>
                    <Spacer space="2" />
                    <Button disabled={updatePasswordIsLoading}>{translate('general.save')}</Button>
                </Form.Column>
            </Form.Root>
        </TextBlock>
    );
});
