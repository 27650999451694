import React from 'react';
import { Link as LinkType } from '~/lib/data-contract';
import { Button, RawHtml, RouterLink } from '~/shared/components';
import { Spacer } from '~/theme';
import { StyledNewsLetter } from './styled';

type NewsletterAreaProps = {
    link?: LinkType;
    text?: string;
};

export const NewsletterArea = ({ text, link }: NewsletterAreaProps) => {
    return (
        <StyledNewsLetter>
            {text && <RawHtml html={text} />}
            <Spacer space="4" />
            {link?.url && (
                <RouterLink href={link.url}>
                    <Button
                        as="a"
                        variant="primary"
                        target={link.target}
                        title={link.title}
                        children={link.text}
                    />
                </RouterLink>
            )}
        </StyledNewsLetter>
    );
};
