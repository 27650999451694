import styled from '@emotion/styled';
import { StyledResetButton } from '~/shared/components/Button';

export const StyledSocialLoginButton = styled(StyledResetButton)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.light,
    border: `1px solid ${theme.colors.light40}`,
    borderRadius: 4,
    height: 40,
    width: 40,
    svg: {
        height: 20,
        width: 20,
    },
}));
