import React, { PropsWithChildren } from 'react';
import { StyledSocialLoginButton } from './styled';

type Props = PropsWithChildren<{
    onClick?: () => void;
    disabled?: boolean;
}>;
export const SocialLoginButton = ({ children, onClick, disabled, ...rest }: Props) => {
    return (
        <StyledSocialLoginButton disabled={disabled} onClick={onClick} type="button" {...rest}>
            {children}
        </StyledSocialLoginButton>
    );
};
