import React, { memo } from 'react';
import { Form, useForm, useTranslation } from '~/shared/utils';
import { Button, InputField, Text, TextBlock } from '~/shared/components';
import { Spacer } from '~/theme';
import { Fields } from '~/shared/utils/form';
import { useAccount, useClub } from '~/features/club/hooks';
import { useEffect } from 'react';

export const ResetPassword = memo(() => {
    const { translate } = useTranslation();
    const { toggleResetPasswordActive } = useClub();
    const { resetPassword, resetPasswordIsLoading, resetPasswordIsSuccess } = useAccount();
    const {
        register,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm({
        validationSchema: 'resetPassword',
    });

    const onSubmit = (values: Fields) => {
        resetPassword(values);
    };

    useEffect(() => {
        if (resetPasswordIsSuccess) {
            toggleResetPasswordActive(false);
        }
    }, [toggleResetPasswordActive, resetPasswordIsSuccess]);

    const mail = watch('email');

    return (
        <TextBlock>
            <Text variant="display4" as="h1">
                {translate('club.resetPassword.headline')}
            </Text>
            <Text>{translate('club.resetPassword.description')}</Text>
            <Form.Root onSubmit={handleSubmit(onSubmit)}>
                <Spacer space="2" />
                <Form.Column>
                    <InputField
                        {...register('email')}
                        invalidMessage={errors.mail?.message}
                        isActive={!!mail}
                        type="email"
                        autoComplete="email"
                        label={translate('form.label.email')}
                        placeholder={translate('form.placeholder.email')}
                    />
                </Form.Column>
                <Spacer space="2" />
                <Form.Column rightAlign>
                    <Button disabled={resetPasswordIsLoading}>
                        {translate('club.resetPassword.buttonText')}
                    </Button>
                </Form.Column>
            </Form.Root>
        </TextBlock>
    );
});
