import styled from '@emotion/styled';
import { Button } from '~/shared/components/Button';
import { indicatePending, scrollbar } from '~/shared/utils/styled';

export const StyledActionField = styled.div(({ theme }) => ({
    display: 'flex',
    paddingBottom: theme.spaces[2],
}));

export const StyledRichTextField = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.light30,
    padding: theme.spaces[3],
    marginTop: theme.spaces[4],
}));

export const StyledSignInForm = styled.form<{ pending?: boolean }>(({ theme, pending }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spaces[4]} ${theme.spaces[3]}`,
    gap: theme.spaces[3],
    ...(pending ? indicatePending : {}),
    overflowY: 'auto',
    ...scrollbar,
}));

export const StyledSignUpButton = styled(Button)({
    backgroundColor: 'transparent',
    width: '100%',
});

export const StyledForgotPasswordButton = styled(Button)(({ theme }) => ({
    fontWeight: theme.fontWeights.bold,
}));
