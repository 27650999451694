import styled from '@emotion/styled';
import { cover } from '~/shared/utils/styled';

export const StyledSocialLogin = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spaces[4],
}));

export const StyledSocialDivider = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',

    '&:after': {
        content: "''",
        ...cover,
        backgroundColor: theme.colors.light30,
        margin: 'auto',
        zIndex: -1,
        height: 1,
    },
    '> p': {
        backgroundColor: theme.colors.light,
        padding: `0 ${theme.spaces[4]}`,
    },
}));
