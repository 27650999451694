import React, { memo, useEffect, useState } from 'react';
import { InputField, TextBlock, Button, RawHtml } from '~/shared/components';
import {
    Form,
    removeEmpty,
    useForm,
    useFrame,
    useGetRequest,
    useTranslation,
} from '~/shared/utils';
import { Text } from '~/shared/components';
import { Fields } from '~/shared/utils/form';
import { Checkbox, DatePicker, PasswordToggle } from '~/shared/components/FormElements';
import { Spacer } from '~/theme';
import { SIGNUP_SOURCE } from '~/features/basket/constants/signup-source';
import { ActivateClubMemberModel } from '~/lib/data-contract';
import { useAccount, useClub, useClubPaths } from '~/features/club/hooks';

type Props = {
    newsletterConsent?: string;
    description?: string;
};

export const ActivateAccount = memo(({ newsletterConsent = '', description = '' }: Props) => {
    const { activateUserSignIn } = useClub();
    const [isPending, setIsPending] = useState(false);
    const { data: frame } = useFrame();
    const { getExistingClubMemberUrl } = useClubPaths();
    const { authHeaders } = useAccount();

    const {
        data: existingMember,
        isLoading: existingMemberPending,
    } = useGetRequest<ActivateClubMemberModel>(getExistingClubMemberUrl, {
        headers: authHeaders,
        enabled: Boolean(authHeaders),
        retry: false,
    });

    const redirectUrl = frame?.staticLinks?.clubPage?.url || '/';

    const shouldShowPassword =
        !existingMemberPending &&
        existingMember?.signupSource !== SIGNUP_SOURCE.CLUB &&
        existingMember?.signupSource !== SIGNUP_SOURCE.CHECKOUT;

    const { translate } = useTranslation();
    const {
        handleSubmit,
        register,
        formState: { errors },
        watch,
        setValue,
    } = useForm({
        validationSchema: 'activateAccount',
    });

    const onSubmit = async (values: Fields) => {
        const { signupSource: _, ...rest } = values;
        const payload = removeEmpty(rest);

        setIsPending(true);
        await activateUserSignIn(payload, redirectUrl);
        setIsPending(false);
    };

    const values = watch();

    useEffect(() => {
        if (!existingMember) {
            return;
        }
        const {
            firstname = '',
            lastname = '',
            birthday = '',
            signupSource,
            phone,
        } = existingMember;

        setValue('firstname', firstname);
        setValue('lastname', lastname);
        setValue('birthday', birthday);
        setValue('signupSource', signupSource);
        setValue('phone', phone);
    }, [existingMember, setValue]);

    return (
        <Form.Root onSubmit={handleSubmit(onSubmit)} pending={isPending || existingMemberPending}>
            <Form.Column>
                <TextBlock>
                    <RawHtml html={description} />
                    <Spacer space="4" />
                </TextBlock>
            </Form.Column>
            <Form.Column>
                <Text variant="caption" children={translate('form.group.name')} />
            </Form.Column>
            <Form.Column column="1 / span 3">
                <InputField
                    {...register('firstname')}
                    invalidMessage={errors?.firstname?.message}
                    isActive={!!values.firstname}
                    autoComplete="given-name"
                    label={translate('form.label.firstName')}
                    placeholder={translate('form.placeholder.firstName')}
                />
            </Form.Column>
            <Form.Column column="4 / span 3">
                <InputField
                    {...register('lastname')}
                    invalidMessage={errors?.lastname?.message}
                    isActive={!!values.lastname}
                    autoComplete="family-name"
                    label={translate('form.label.lastName')}
                    placeholder={translate('form.placeholder.lastName')}
                />
            </Form.Column>
            <Form.Column>
                {shouldShowPassword && (
                    <PasswordToggle
                        {...register('password')}
                        invalidMessage={errors.password?.message}
                        isActive={!!values.password}
                        label={translate('form.label.password')}
                        placeholder={translate('form.placeholder.password')}
                        type="password"
                    />
                )}
            </Form.Column>
            <Form.Column>
                <DatePicker
                    {...register('birthday')}
                    label={translate('form.label.birthday')}
                    invalidMessage={errors?.birthday?.message}
                    autoComplete="bday"
                />
            </Form.Column>
            <Form.Column>
                <InputField
                    {...register('phone')}
                    invalidMessage={errors?.phone?.message}
                    isActive={!!values.phone}
                    type="tel"
                    autoComplete="tel-national"
                    label={translate('form.label.phone')}
                    placeholder={translate('form.placeholder.phone')}
                />
            </Form.Column>

            <Form.Column>
                <Checkbox
                    {...register('notifyBySms')}
                    label={<RawHtml html={newsletterConsent} />}
                />
            </Form.Column>

            <Form.Column rightAlign>
                <Spacer space="3" />
                <Button disabled={isPending}>{translate('general.activate')}</Button>
            </Form.Column>
        </Form.Root>
    );
});
