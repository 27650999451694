import React, { memo, useCallback } from 'react';
import {
    SignUp,
    SignIn,
    EditAccount,
    ResetPassword,
    ActivateAccount,
    DeleteAccount,
    EditAccountStore,
    EditPassword,
} from '../';
import { Overlay } from '~/shared/components';
import { useTranslation } from '~/shared/utils';
import { useClub } from '../../hooks';
import { StyledClubOverlays, StyledClubDialogContainer, StyledSignInContainer } from './styled';

export const ClubOverlays = memo(() => {
    const {
        signUpActive,
        signUpActiveKeepScroll,
        toggleSignUpActive,
        signInActive,
        toggleSignInActive,
        editAccountActive,
        toggleEditAccountActive,
        editAccountStoreActive,
        toggleEditAccountStoreActive,
        editPasswordActive,
        toggleEditPasswordActive,
        deleteAccountActive,
        toggleDeleteAccountActive,
        resetPasswordActive,
        toggleResetPasswordActive,
        activateUserActive,
        toggleActivateUserActive,
        loginPage,
        isAuthenticated,
    } = useClub();
    const { translate } = useTranslation();
    const { clubName = '' } = loginPage || {};
    const signInOpen = Boolean(signInActive && loginPage);
    const onSignUpDismiss = useCallback(() => toggleSignUpActive(false), [toggleSignUpActive]);
    const onSignInDismiss = useCallback(() => toggleSignInActive(false), [toggleSignInActive]);
    const onResetPasswordDismiss = useCallback(() => toggleResetPasswordActive(false), [
        toggleResetPasswordActive,
    ]);
    const onActivateUserDismiss = useCallback(() => toggleActivateUserActive(false), [
        toggleActivateUserActive,
    ]);
    const onEditAccountDismiss = useCallback(() => toggleEditAccountActive(false), [
        toggleEditAccountActive,
    ]);
    const onEditAccountStoreDismiss = useCallback(() => toggleEditAccountStoreActive(false), [
        toggleEditAccountStoreActive,
    ]);
    const onEditPasswordDismiss = useCallback(() => toggleEditPasswordActive(false), [
        toggleEditPasswordActive,
    ]);
    const onDeleteAccountDismiss = useCallback(() => toggleDeleteAccountActive(false), [
        toggleDeleteAccountActive,
    ]);

    return (
        <StyledClubOverlays>
            <Overlay
                onDismiss={onSignUpDismiss}
                open={signUpActive}
                headline={translate('club.signUp.dialogHeader')}
                scrollLock={!signUpActiveKeepScroll}
            >
                <StyledClubDialogContainer>
                    <SignUp />
                </StyledClubDialogContainer>
            </Overlay>

            <StyledSignInContainer>
                <Overlay
                    onDismiss={onSignInDismiss}
                    open={signInOpen}
                    headline={translate('club.signIn.dialogHeader', { clubName })}
                    variant="slide"
                >
                    <SignIn />
                </Overlay>
            </StyledSignInContainer>

            <Overlay
                onDismiss={onResetPasswordDismiss}
                open={resetPasswordActive}
                headline={translate('club.resetPassword.dialogHeader', { clubName })}
            >
                <StyledClubDialogContainer>
                    <ResetPassword />
                </StyledClubDialogContainer>
            </Overlay>

            <Overlay
                onDismiss={onActivateUserDismiss}
                open={activateUserActive}
                headline={translate('club.activateUser.dialogHeader')}
            >
                <StyledClubDialogContainer>
                    <ActivateAccount />
                </StyledClubDialogContainer>
            </Overlay>

            {isAuthenticated && (
                <>
                    <Overlay
                        onDismiss={onEditAccountDismiss}
                        open={editAccountActive}
                        headline={translate('club.edit.dialogHeader')}
                    >
                        <StyledClubDialogContainer>
                            <EditAccount />
                        </StyledClubDialogContainer>
                    </Overlay>
                    <Overlay
                        onDismiss={onEditAccountStoreDismiss}
                        open={editAccountStoreActive}
                        headline={translate('club.editAccountStore.dialogHeader')}
                    >
                        <StyledClubDialogContainer>
                            <EditAccountStore />
                        </StyledClubDialogContainer>
                    </Overlay>
                    <Overlay
                        open={editPasswordActive}
                        onDismiss={onEditPasswordDismiss}
                        headline={translate('club.editPassword.dialogHeader')}
                    >
                        <StyledClubDialogContainer>
                            <EditPassword />
                        </StyledClubDialogContainer>
                    </Overlay>
                    <Overlay
                        onDismiss={onDeleteAccountDismiss}
                        open={deleteAccountActive}
                        headline={translate('club.deleteAccount.dialogHeader')}
                    >
                        <StyledClubDialogContainer>
                            <DeleteAccount />
                        </StyledClubDialogContainer>
                    </Overlay>
                </>
            )}
        </StyledClubOverlays>
    );
});
