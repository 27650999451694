import React from 'react';
import User from '$icons/solidAsh/user.svg';
import { RouterLink, Tooltip } from '$shared/components';
import { useClub } from '~/features/club';
import { StyledMetaButton } from '../styled';
import { useFrame, useTranslation } from '~/shared/utils';

export const AvatarButton = () => {
    const { isAuthenticated, toggleSignInActive } = useClub();
    const { data: frame } = useFrame();
    const { translate } = useTranslation();
    const clubUrl = frame?.staticLinks?.clubPage?.url || '';
    const labelContent = isAuthenticated
        ? translate('accessibility.goToAccount')
        : translate('accessibility.signInSignUp');

    if (!clubUrl) return null;

    return (
        <Tooltip
            content={labelContent}
            describedBy="avatar-tooltip"
            style={{ whiteSpace: 'nowrap' }}
        >
            {isAuthenticated ? (
                <RouterLink href={clubUrl}>
                    <StyledMetaButton as="a">
                        <User />
                    </StyledMetaButton>
                </RouterLink>
            ) : (
                <StyledMetaButton
                    onClick={() => toggleSignInActive(true)}
                    aria-label={labelContent}
                >
                    <User />
                </StyledMetaButton>
            )}
        </Tooltip>
    );
};
