import { Accordion } from '$shared/components';
import React, { memo } from 'react';
import { NavigationNode } from '~/lib/data-contract';
import { MenuItem } from './components/MenuItem';
import { StyledMenuContainer } from './styled';

type MenuAreaProps = {
    menu?: NavigationNode[];
};

export type MenuItemProps = {
    menuItem: NavigationNode;
    identifier?: string;
};

export const MenuArea = memo(({ menu }: MenuAreaProps) => (
    <Accordion>
        <StyledMenuContainer>
            {menu?.map((menuItem: MenuItemProps['menuItem'], index) => (
                <MenuItem key={index} identifier={`${index}`} menuItem={menuItem} />
            ))}
        </StyledMenuContainer>
    </Accordion>
));
