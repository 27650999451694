import React, { memo } from 'react';
import { useFrame } from '~/shared/utils';
import { StyledContent, StyledN11Meta } from './styled';
import { Usp } from './components/Usp';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';

const N11Meta = memo(() => {
    const { data } = useFrame();
    const { uspList } = data?.usp || {};

    return (
        <StyledN11Meta>
            <StyledContent>
                {uspList?.length ? <Usp uspItems={uspList} adjustColorsToBackground /> : null}
            </StyledContent>
        </StyledN11Meta>
    );
});

export default withErrorBoundary(N11Meta);
