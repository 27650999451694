import ChevronDown from '$icons/chevron-down.svg';
import React, { memo } from 'react';
import {
    StyledNavigationItem,
    StyledLineContainer,
    StyledLineAnimation,
    StyledNavigationText,
} from './styled';
import { Link, NavigationNode } from '~/lib/data-contract';
import { Line } from '~/shared/components/Line';
import { slideVariants } from '~/shared/animations/slide-in-out';
import { StyledButton } from '~/shared/components';
import { bezierValues, durations } from '~/theme/themes/animations/baseAnimations';
import { useTheme } from '@emotion/react';
import { RouterLink } from '~/shared/components/RouterLink';
import { useThemeShade } from '~/theme';

type Props = {
    link?: Link;
    subNodes?: NavigationNode[];
    active?: boolean;
    onClick?: () => void;
    linkColor?: string;
    isLightHeader?: boolean;
};

/**
 * Navigation item, which will render either as a link or button, based on it's childnodes presence.
 * has a active and inactive state.
 */
export const NavigationItem = memo((props: Props) => {
    const { link, subNodes, active, onClick, isLightHeader, ...rest } = props;
    const { spaces, traits } = useTheme();
    const { backgroundColor, textShade, lineShade } = useThemeShade({
        backgroundColor: traits.background.n21mainMenu,
    });

    return (
        <>
            {(!!subNodes?.length && (
                <>
                    <StyledNavigationItem
                        onClick={onClick}
                        aria-expanded={props.active}
                        {...rest}
                        as={StyledButton}
                        menuColor={backgroundColor}
                        textShade={!isLightHeader ? textShade : 'paragraph'}
                        isLightHeader={isLightHeader}
                    >
                        <StyledNavigationText
                            as="span"
                            shade={!isLightHeader ? textShade : 'paragraph'}
                            variant="bodySm"
                            linkColor={props.linkColor}
                        >
                            {link?.text || link?.title}
                        </StyledNavigationText>
                        <ChevronDown aria-hidden="true" />
                    </StyledNavigationItem>
                    <StyledLineContainer>
                        <StyledLineAnimation
                            variants={slideVariants}
                            animate={active ? 'animate' : 'exit'}
                            initial="exit"
                            exit="exit"
                            custom={{
                                transitionConfig: {
                                    entranceTransition: {
                                        ease: bezierValues.entrance.expressive,
                                        duration: durations.moderate01,
                                    },
                                    exitTransition: {
                                        ease: bezierValues.exit.expressive,
                                        duration: durations.moderate01,
                                    },
                                    translateX: '100%',
                                },
                            }}
                        >
                            <Line
                                thickness={spaces[1]}
                                shade={!isLightHeader ? lineShade : 'colorInverted'}
                            />
                        </StyledLineAnimation>
                    </StyledLineContainer>
                </>
            )) ||
                (link?.url && (
                    <RouterLink prefetch={false} href={link.url} {...rest}>
                        <StyledNavigationItem
                            title={link.title}
                            target={link.target}
                            menuColor={backgroundColor}
                            textShade={!isLightHeader ? textShade : 'paragraph'}
                            isLightHeader={isLightHeader}
                        >
                            <StyledNavigationText
                                as="span"
                                shade={!isLightHeader ? textShade : 'paragraph'}
                                variant="bodySm"
                                linkColor={props.linkColor}
                            >
                                {link.text || link?.title}
                            </StyledNavigationText>
                        </StyledNavigationItem>
                    </RouterLink>
                ))}
        </>
    );
});
