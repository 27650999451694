import { Router } from 'next/router';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { NavigationNode } from '~/lib/data-contract';
import {
    StyledList,
    StyledDropDownContent,
    StyledDropdown,
    StyledDropDownLine,
    StyledDropDownBackdrop,
} from './styled';
import { Menu, NavigationItem, PromotedChildren } from './components';
import { AnimatePresence, useReducedMotion } from 'framer-motion';
import { useClickAway, usePrevious } from 'react-use';
import {
    dropDownContentVariants,
    dropDownVariants,
    lineVariants,
    menuSectionVariants,
} from './animations';
import { useTheme } from '@emotion/react';
import { usePortal } from '~/shared/hooks/usePortal/usePortal';
import { ClickTracker } from '~/shared/components';
import { useTrackMenuClick } from '../N35MobileMegaMenu';
import { isSSR } from '~/shared/utils';

export type Props = {
    navigation: NavigationNode[];
    isLightHeader?: boolean;
};

/**
 * A basic navigation with related mega menu.
 * covers basic SEO and accessibility needs.
 * Handles active/inactive state and close on route change.
 */
export const N31MegaMenu = memo(({ navigation, isLightHeader }: Props) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const previousActiveIndex = usePrevious<number | null>(activeIndex);
    const navigationRef = useRef<HTMLDivElement>(null);
    const shouldReduceMotion = useReducedMotion();
    const theme = useTheme();
    const { track } = useTrackMenuClick();
    const Portal = usePortal(isSSR ? null : document.querySelector('body'));

    const keyMap = useCallback(
        (key: string) =>
            (({
                Escape: () => setActiveIndex(null),
            } as Record<string, () => void>)[key]?.()),
        []
    );

    useClickAway(navigationRef, () => {
        setActiveIndex(null);
    });

    // Close menu as soon as possible when navigating
    const onRouteChangeStart = () => {
        setActiveIndex(null);
    };

    useEffect(() => {
        Router.events.on('routeChangeStart', onRouteChangeStart);
        return () => {
            Router.events.off('routeChangeStart', onRouteChangeStart);
        };
    }, []);

    return (
        <ClickTracker
            as="nav"
            callback={(anchor: HTMLAnchorElement) =>
                track(anchor, activeIndex !== null ? navigation[activeIndex] : undefined)
            }
        >
            <StyledList onKeyDown={({ key }) => keyMap(key)}>
                {navigation?.map(({ children, promotedChildren, link, id, linkColor }, index) => {
                    const isActive = index === activeIndex;
                    const wasActive = index === previousActiveIndex;
                    const isAnyActive = activeIndex !== null;
                    const wasAnyActive =
                        previousActiveIndex !== null && previousActiveIndex !== undefined;

                    let animateState: 'active' | 'inactive' | 'switchIn' | 'switchOut' = 'inactive';
                    if (isActive) {
                        animateState = wasAnyActive ? 'switchIn' : 'active';
                    } else if (wasActive && isAnyActive) {
                        animateState = 'switchOut';
                    }

                    return (
                        <li key={id}>
                            <NavigationItem
                                active={isActive}
                                onClick={() => setActiveIndex(isActive ? null : index)}
                                subNodes={children}
                                link={link}
                                linkColor={linkColor}
                                isLightHeader={isLightHeader}
                            />
                            {!!children?.length && (
                                <StyledDropdown
                                    animate={animateState}
                                    initial="inactive"
                                    variants={dropDownVariants(shouldReduceMotion, theme)}
                                    isLightHeader={isLightHeader}
                                >
                                    <StyledDropDownContent
                                        variants={dropDownContentVariants(
                                            shouldReduceMotion,
                                            theme
                                        )}
                                    >
                                        <Menu
                                            nodes={children}
                                            link={link}
                                            variants={menuSectionVariants(
                                                shouldReduceMotion,
                                                theme
                                            )}
                                            isLightHeader={isLightHeader}
                                        />

                                        <PromotedChildren
                                            nodes={promotedChildren}
                                            variants={menuSectionVariants(
                                                shouldReduceMotion,
                                                theme,
                                                'fast01'
                                            )}
                                        />
                                    </StyledDropDownContent>
                                    <StyledDropDownLine
                                        aria-hidden="true"
                                        animate={animateState}
                                        variants={lineVariants(shouldReduceMotion, theme)}
                                    />
                                </StyledDropdown>
                            )}
                        </li>
                    );
                })}
            </StyledList>
            <AnimatePresence>
                {activeIndex !== null && (
                    <Portal>
                        <StyledDropDownBackdrop
                            onClick={() => setActiveIndex(null)}
                            role="button"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        />
                    </Portal>
                )}
            </AnimatePresence>
        </ClickTracker>
    );
});
