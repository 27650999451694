import React, { memo } from 'react';
import { FooterIcon } from '~/lib/data-contract';
import { GridItem } from '../GridItem';
import { Icons } from './components';
import { StyledIconArea, StyledImageWrapper } from './styled';

type MetaAreaProps = {
    paymentIcons?: FooterIcon[];
    additionalIcons?: FooterIcon[];
};

export const IconArea = memo(({ paymentIcons, additionalIcons }: MetaAreaProps) => {
    return (
        <GridItem area="icons">
            <StyledIconArea>
                <StyledImageWrapper>
                    {paymentIcons?.length ? <Icons icons={paymentIcons} /> : null}
                    {additionalIcons?.length ? <Icons icons={additionalIcons} /> : null}
                </StyledImageWrapper>
            </StyledIconArea>
        </GridItem>
    );
});
