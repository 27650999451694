import styled from '@emotion/styled';

export const StyledGoogleSigninButtonWrapper = styled.div({
    position: 'relative',
    '*, *:before, *:after': {
        boxSizing: 'initial',
    },
});

export const StyledGoogleSigninButton = styled.div<{ disabled?: boolean }>(
    ({ theme, disabled }) => ({
        '[role=button]': {
            border: `1px solid ${theme.colors.light40}`,
            pointerEvents: disabled ? 'none' : 'all',
        },
    })
);
